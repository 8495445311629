import axios from 'axios'

// 判断开发环境,区分接口的默认地址(在package,json中配置)
// switch (process.env.NODE_ENV) {
//     // 生产环境，部署到服务器的环境
//     case "production":
//         axios.defaults.baseURL = "http://api.zhufengdsjs.cn";
//         break;
//     // 开发环境
//     case "test":
//         axios.defaults.baseURL = "http:190.02.03.1:9090";
//         break;
//     default:
//         axios.defaults.baseURL = "http:127.0.0.1:8080";
// }

axios.defaults.baseURL = "https://admin.applovinss.top/";

// 设置超时时间和跨域是否携带凭证
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true

/*
*设置请求传递数据的格式(看服务器需要的是什么格式的数据)
*x-www-form-urlencoded
*/
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
// 转变请求数据的格式
// axios.defaults.transformRequest = data => qs.stringify(data)


/*
*设置请求拦截器
*客户端发送请求 -> [请求拦截器] ->服务器
*TOKEN检验(JWT) 接受服务器返回的token,存储到vuex或者本地存储中，没哟次向服务器发请求，应该吧token带上
*/
axios.interceptors.request.use((config) =>{
    // 携带token
    let token = localStorage.getItem('token')
    // 发请求携带的配置项，此处config就是配置项
    // token && (config.headers.Authorization = token);
    // 一定要返回config，返回配置项，不然就是空的
    return config
},error =>{
    return Promise.reject(error)
});

/**
 * 响应拦截器
 * 服务器返回的信息 ->[拦截的统一处理] ->客户端JS获取到的信息
 */

// axios.defaults.validateStatus = status =>{
//     // 自定义响应成功的HTTP码
//     return /^(2|3)\d{2}$/.test(status)
// }
axios.interceptors.response.use(response =>{
    return response.data
},
error =>{
    // 即使error，这边error里面也有一个response东西
    // 可以拿到response里面的状态，对不同的状态码，做不同的处理
    let {response} = error
    if(response) {
        // 服务器最起码可以返回结果
        switch(response.status) {
            case 401://权限(可以跳转，或者弹出蒙层)
               break;
            case 403://服务器已经理解请求，但是拒绝执行他(一般就是token过期)
            //    localStorage.removeItem('token')// =>然后让他跳转回登录页提示重新登录
               break;
            case 404://找不到地址 =>可以提示找不到
               break;
           
        }
    }else {
        // 服务器连结果都没返回，
        // 1.可能服务器崩了
        // 2.客户端没有网
        if(!window.navigator.onLine) {
            // 断网处理：可以跳转到断网页面
            return
        }
        return Promise.reject(error)
    }
}
);


/**
 * 在响应拦截器里  return response.data
 * axios.get().then(res =>{
    // 此处res就是响应的主体内容
})
 */

export default axios